import AnimatorPage from 'components/pages/be-an-animator/AnimatorPage';
import { graphql } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const PageEnAnimator = () => (
	<div>
		<Helmet>
			<meta name="robots" content="noindex" />
		</Helmet>

		<AnimatorPage />
	</div>
);

export default PageEnAnimator;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
